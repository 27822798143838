.content-container {
  background: $primary-red !important;
  margin-top: -50px !important;
}

/* Place the navbar at the bottom of the page, and make it stick */
.bottom-menu {
  background-color: $primary-red;
  overflow: hidden;
  position: sticky !important;
  bottom: 0 !important;
  display: none;
  margin-bottom: -16px !important;
  margin-left: -40px !important;
  z-index: 200 !important;
}

.bottom-menu li{
  display: flex!important;
  justify-content: center !important;
  width: 100% !important;
}


.bottom-menu li a {
  display: inline-block !important;
  text-align: center;
  padding: 5px 16px;
  text-decoration: none;
  font-size: 17px;
  list-style: none !important;
}

.bottom-menu li:not(:last-child) {
  border-right: 1px solid $light-blue-100!important;
}

.bottom-menu li a span{
  display: block;
  align-items: center !important;
}

.bottom-menu li{
  list-style: none !important
}

.hover-bottom-item-background:hover {
  background-color: $light-blue-100!important;
  color: $primary-red !important;
}

.hover-bottom-item-color {
  color: $primary-red !important;
}

.active-bottom-menu-item {
  background-color: $light-blue-100 !important;
  color: $primary-red!important;
}

.bottom-menu-text-color {
  color: $primary-white;
  font-size: 10px !important;
  margin-top: 2px;
  font-weight: 600;
  font-size: 13px !important;
}

@media (max-width: 991px) {
  .bottom-menu {
    display: flex;
  }

  .bottom-menu li a .svg-icon {
    margin-left: 0 !important;
  }
}

