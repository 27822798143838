.profile-container {
    background-color: $light-blue-100;
    border-radius: 16px;
}

.investor-profile-title{
    font-size: 30px;
}

.chart-title {
    font-size: 20px;
}

.chart-sub-title {
    font-size: 14px;
}

.next-step-button {
    width: 100%;
    background-color: $primary-red;
    border: $primary-red;
    color: $primary-white;
    border-radius: 10px;
    padding: 15px 0;
    font-size: 20px;
}