.red-text-color {
    color: $primary-red;
}

.welcome-text {
    font-size: 20px;
    line-height: 0;
}

.next-button {
    font-weight: 500;
    outline: none;
    border: none;
}

.skip {
    font-size: 25px;
}

.how-it-works-text {
    color: $dark-gray-600;
}

.page-indicator {
    width: 15px;
    height: 15px;
    border-radius: 100%;
}

.red-dot{
    background: $primary-red;
}

.gray-dot{
    background: $gray-400;
}