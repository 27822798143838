.aside-menu-container {
  background-color: $primary-red !important;
}

.dashboard-link {
  background-color: $light-blue-300 !important;
  padding: 15px !important;
  border-bottom: 0.5px solid $primary-white;
}

.menu-text-color {
  color: $primary-white !important;
  font-size: 16px !important;
  margin-left: 15px !important;
}

.padding-bottom {
  padding: 15px !important;
  border-bottom: 0.5px solid $primary-white;
}

.active-menu-item {
  background-color: $light-blue-100 !important;
  color: $primary-red !important;
}

.hover-background:hover {
  background-color: $light-blue-100 !important;
}

.hover-color {
  color: $primary-red !important;
}
