.goal-progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    background-color: $red-100;
    border-radius: 0.42rem;
    box-shadow: none;
    // background-color: $red-100 !important;
}

.goalProgressBarColor {
    background: $primary-red !important
}

.goal-progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    background-color: $primary-red;
    transition: width 0.6s ease;
}