$dark-blue: rgba(0, 113, 206, 0.25);

$blue: rgba(0, 113, 206, 0.5);
$blue-500: #85C3E8;

$primary-blue: #0071ce;
$light-blue: #9fd0f9;
$light-blue-500: #a7d7ff;
$light-blue-450: #cae2f6;
$light-blue-400: #CAE2F6;
$light-blue-350: #e0ebf5;
$light-blue-300: rgba(217, 234, 248, 1);
$light-blue-250: #D0DFE6;
$light-blue-200: #d9eaf8;
$light-blue-100: #deecf7;

$dark-blue-500: #0071ce9c;

$primary-white: #ffffff;
$second-white: #f5f9fd;
$white-600: rgba(255, 255, 255, 1);
$white-400: #f2f8fd;


$dark-gray-700: rgba(0, 113, 206, 0.05);
$dark-gray-600: #3f4254;
$dark-gray-500: #545454;
$dark-gray-400: rgba(202, 226, 246, 0.25);
$dark-gray-300: #F3F3F3;


$gray-600: #D0DFE6;
$gray-500: #c4c4c4;
$gray-400: #D9D9D9;
$gray-300: #c4c4c4;
$gray-200: #e8ebee;
$gray-100: #e4e1e1;


$primary-orange: #ff7f30;

$primary-red: #EC1A23;
$red-400: rgba(236, 26, 35, 0.5);
$red-100: #FFDCDD;

$green-600: #6D9704;
$green-400: #95BA39;


$primary-black: #000;
$black-600: #1F3043;
$black-400: #5F5F5F;
