.registration-title {
  font-weight: 300 !important;
}

.verify-email-container {
  width: 51% !important;
}

.resend-verification-btn {
  color: #0071ce !important;
  background: rgba(0, 113, 206, 0.25) !important;
  margin-right: 25px;
}

.input-spacing {
  margin-bottom: 25px;
}

.password-spacing {
  margin-bottom: 35px;
}

.error-spacing {
  position: relative;
  margin-top: 15px;
}

.error-message-spacing {
  margin-top: 15px;
}

@media (max-width: 480px) {
  .mobile-row {
    flex-direction: column !important;
  }
  .btn-mobile {
    width: 100% !important;
  }

  .display-none {
    display: none !important;
  }

  .verify-email-container {
    width: 100% !important;
  }

  .resend-verification-btn {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .display-none {
    display: none !important;
  }
}
