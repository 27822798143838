.new-investment-card {
  background: #DEECF7 !important;
  margin-right: 10px;
  border: 0.456132px solid #2d324b1a !important;
    border-radius: 6px !important;
 }
  
 .promo-investment-card {
  background: #f2f8fd !important;
  margin-right: 10px;
  box-shadow: none!important;
 }
  
 .select-investment-btn {
  background: #0071ce !important;
  color: #fff !important;
  height: 34px;
    margin-top: 7px;
 }
  
 .heading-text {
  color: #0071ce;
  font-size: 20px;
  margin-bottom: 8px;
 }
  
 .last-card {
  width: 50%;
 }
  
 .dhamana-container {
  margin-right: 10px;
 }
  
 .dhamana-heading {
  color: #ffe600;
  font-size: 22px;
 }
  
 .dhamana-sub-header {
  font-size: 18px;
  line-height: 21px;
 }
  
 .dhamana-closing-date {
  font-size: 16px;
  font-weight: 500;
 }
  
 .select-btn-text {
  font-size: 12px;
 }
  
 .new-investment-text {
  font-size: 17px;
 }
  
 @media (min-width: 768px) {
  .new-investment-main-container {
    grid-template-columns: repeat(2, 1fr)
  }
 }
 @media (max-width: 891px) {
  .new-investment-main-container {
    flex-wrap: wrap !important;
  }
  
  .last-card {
    width: 100%;
  }
  
  .dhamana-container {
    margin-right: 0;
  }
 }
 @media (max-width: 566px) {
  .promo-investment-card{
    height: 240px!important;
  }
  .new-investment-card{
    height: 240px!important;
  
  }
  .order-one {
    order: 1 !important;
    margin: 15px 0 !important;
  }
  .select-investment-btn {
    margin: 15px 0 !important;
  }
 }
  
 @media (max-width: 422px) {
  .heading-text {
    font-size: 17px !important;
    margin-bottom: 5px !important;
  }
  
  .new-investment-text {
    font-size: 14px;
  }
 }
  
 @media (max-width: 384px) {
  .dhamana-heading {
    color: #ffe600;
    font-size: 18px;
  }
  
  .dhamana-sub-header {
    font-size: 12px;
    line-height: 16px;
  }
  
  .dhamana-closing-date {
    font-size: 12px;
    line-height: 16px;
  }
 }
 