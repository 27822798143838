.goal-summary-container {
    border: 2px solid $red-400;
    border-radius: 16px;
    padding: 32px;
}

.info-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    flex-shrink: 0;
}