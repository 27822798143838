.dashboard-card {
  height: 180px !important
}
.dashboard-bottom-header {
  display: none !important;
}

.dashboard-company {
  width: 25%;
  height: 27px;
}

.desktop-dashboard-company {
  width: 25%;
  max-height: 75px !important;
  padding: 0 5px;
}

.mobile-dashboard-company-logos {
  display: none;
}

.dashboard-logo-header {
  font-size: 23px !important;
}

.title-size {
  font-size: 18px;
}

.values-size {
  font-size: 25px;
}

.dashboard-company-logos {
  border: 1px solid $gray-200 !important;
  border-radius: 5px;
}

.dashboard-company-logos-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.licenced-Content {
  flex: 1 1 0px;
  text-align: justify;
  margin-left: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.dashboard-card-blue-background-color { 
  background: $primary-blue !important;
}

.dashboard-card-white-background-color { 
  background: $light-blue-100 !important;
}

.dashboard-card-gray-background-color { 
  background: $dark-gray-500 !important;
}

.welcome-text-color {
  color: $primary-blue
}

.gray-title {
  color: $dark-gray-600;
}

.white-title {
  color: $primary-white
}

.sub-text {
  color: $primary-white;
  line-height: 15px;
  font-size: 13px
}

.dashboard-card-image { 
  width: 100%;
  height: 100%
}

.dashboard-card-x-small-text {
  font-size: 14px;
  margin-top: 5px;
  font-weight: 500;
}

.dashboard-amount-value {
  color: $primary-red;
  font-weight: 400;
  line-height: 20px !important
}

.portfolio-value-profit {
  color: $dark-gray-600;
}

.dashboard-cards-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}

.fund-plans{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
}

.learn-card{
  max-width: 180px;
  min-height: 100px;
  border-radius: 8px;
}

.learn-card-play{
  width: 39.6px;
  height: 39.6px;
  margin: auto;
  background: rgba(186, 186, 186, 0.5);
  border-radius: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-items: center;
  margin-left: 52px;
}

.learn-card-time{
  width: 44px;
  height: 18px;
  background: #545454;
  border-radius: 26.7568px;
  display: flex;
  justify-items: center;
  align-items: center;
  margin: 10px;
}

.triangle {
  position: relative;
  background-color: #FFFFFF;
  text-align: left;
  margin: 11px 0px 16px 14px;
}
.triangle:before,
.triangle:after {
  content: '';
  position: absolute;
  background-color: inherit;
}
.triangle,
.triangle:before,
.triangle:after {
  width:  10px;
  height: 10px;
  border-top-right-radius: 30%;
}

.triangle {
  transform: rotate(-90deg) skewX(-30deg) scale(1,.866);
}
.triangle:before {
  transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);  }
.triangle:after {
  transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);  
}

.view-more {
  font-weight: 600;
  font-size: 25px;
  line-height: 28px;
  letter-spacing: -0.03em;
  color: #FFFFFF;
  font-family: 'Poppins';
  font-weight:bold;
  word-wrap: break-word;
}

.view-more:hover {
  color: #FFFFFF;
  text-decoration: none;
}

.learn-card-title{
  width: 335px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 119%;
  /* or 20px */
  letter-spacing: -0.05em;
  color: #3F4254;
}

.videos-title {
  font-size: 18px;
  margin-left: 40px;
}

@media (max-width: 768px) {
  .dashboard-company-logos-container {
    flex-direction: column;
  }

  .dashboard-company-logos {
    width: 100%;
  }

  .licenced-Content {
    margin-top: 20px;
    margin-left: 0;
  }

  .dashboard-logo-header {
    font-size: 16px !important;
  }
}

@media (max-width: 667px) {
  .mobile-dashboard-company-logos {
    display: block;
    margin-top: 10px;
    background-color: $primary-white;
    border-radius: 5px;
  }

  .dashboard-logo-header {
    font-size: 16px !important;
  }
}

@media (max-width: 667px) {
  .mobile-dashboard-company-logos {
    display: block;
    margin-top: 10px;
    background-color: $primary-white;
    border-radius: 5px;
  }

  .dashboard-logo-header {
    font-size: 16px !important;
  }

  .learn-card{
    max-width: 180px;
    min-height: 105px;

      .learn-card-play{
        width: 39px;
        height: 39px;
      }
  }

  .learn-card-title{
    width: 293.9px;
    font-size: 13.9562px;
  }

  .learning-modal {
    .modal-dialog{
      .modal-content{
        width: 700px;
        height: 300px;
      }
    }
    // width: 90vw;    /* Occupy the 90% of the screen width */
    // max-width: 90vw;
  }

   // modal
   .learning-modal {
    .modal-dialog{
      .modal-content{
        width: 700px;
        max-width: 700px;
        height: 300px;
      }
    }
    // width: 90vw;    /* Occupy the 90% of the screen width */
    // max-width: 90vw;
  }

  .triangle {
    position: relative;
    background-color: #FFFFFF;
    text-align: left;
    margin: 11px 0px 16px 14px;
  }
}

@media (max-width: 594px) {
  .dashboard-card {
    margin-right: 0 !important;
  }
}

@media (max-width: 540px) {
  .dashboard-bottom-header {
    display: block !important;
  }
  .btn-special {
    width: 90%;
  }
}

@media (max-width: 435px) {
  .dashboard-image-mobile {
    width: 85px;
    height: 75px;
  }

  .title-size {
    font-size: 15px;
  }

  .values-size {
    font-size: 16px;
  }
}

@media (max-width: 399px) {
  .dashboard-card {
    height: auto !important;
  }

  .learn-card{
    max-width: 157px;
    min-height: 87px;
  }
}
