.bg-info-blue {
  height: auto;
  background: $primary-red !important;
  color: $primary-white !important;
  border-radius: 16px;
}

.wizard-custom-selector {
  height: auto;
}

.chosen {
  color: $primary-white !important;
}

.exit {
  font-size: 20px;
  margin-top: -33px;
  color: $primary-blue;
  padding-bottom: -5px;
  cursor: pointer;
  float: right;
  font-weight: 500;
}

.selected-answer-box{
  background: $primary-red !important;
  color: $primary-white !important;
  border: none !important;
  font-weight: 500 !important;
  border-radius: 16px;
}

.unselected-answer-box {
  background-color: $light-blue-100 !important;
  border:  0.386144px solid $light-blue-400 !important;
  border-radius: 16px;
  font-weight: 500 !important;
}

@media (max-width: 768px) {
  .wizard-custom-selector {
    height: auto !important;
  }
  .bg-info-blue {
    height: auto !important;
  }

  .exit {
    font-size: 18px;
  }
}

@media (max-width: 425px) {
  .exit {
    font-size: 16px;
    float: none;
    margin-top: 0;
  }
}
