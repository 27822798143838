.common-btn {
  background: $primary-blue;
  border: none;
  border-radius: 5px;
  color: $primary-white;
}

.red-btn {
  background: $primary-red;
  border: none;
  border-radius: 5px;
  color: $primary-white;
}
.disabled-btn {
  background: $red-400;
  border: none;
  border-radius: 5px;
  color: $primary-white;
}

.btn-size {
  width: 167px;
  height: 47px;
}

.btn-heigth {
  height: 47px;
}

.delete-button {
  background: $light-blue-400;
  height: 47px;
  border: none;
  border-radius: 5px;
  color: $primary-blue;
}

.delete-btn-px {
  padding: 0 16px;
}

.view-btn-px {
  padding: 0 22px;
}

.center-btn {
  margin-left: auto;
  margin-right: auto;
}

.cancel-btn-padding {
  padding: 1rem 2.25rem !important;
}

.watchlist-btn {
  width: 250px;
  height: 47px;
  border: 0.5px solid $dark-gray-600;
  border-radius: 5px;
  color: $primary-white;
  background-color: $dark-gray-600;
}

.remove-watchlist-btn {
  background-color: $dark-gray-600;
  border: 0.5px solid $dark-gray-600;
  border-radius: 5px;
  color: $primary-white;
}

@media screen and (max-width: 1306px) {
  .btn-responsive-width {
    width: 250px;
  }
}

@media screen and (max-width: 768px) {
  .btn-responsive-width {
    width: 167px;
  }
}

@media screen and (max-width: 584px) {
  .btn-responsive-width {
    width: 160px;
  }
}

@media screen and (max-width: 496px) {
  .btn-responsive-width {
    width: 140px;
  }
}

@media screen and (max-width: 480px) {
  .btn-size {
    width: 100%;
    height: 47px;
  }

  .watchlist-btn {
    width: 100%;
    margin-left: 0;
  }
}

.margin-left {
  margin-left: 1.25rem !important;
}

@media screen and (max-width: 320px) {
  .display-small-block {
    display: block;
    margin-top: 10px;
    margin-left: -1.25rem !important;
  }

  .full-width-small {
    width: 100% !important;
  }

  .margin-left {
    margin-left: 0 !important;
  }
}
