.animated-card > * {
  pointer-events: none;
}

.animated-card {
  cursor: pointer;
}

.gutter-b {
  margin-bottom: 12px !important;
}

.bg-info {
  color: $primary-white !important;

  .option-text {
    color: $primary-white !important;
  }
}

.bg-info-blue {
  height: 66px;
  background: $primary-red !important;
  color: $primary-white;
}
.input-form-bg {
  height: 66px !important;
  background: $dark-gray-700 !important;
  border: 0.5px solid $blue !important;
  border-radius: 5px !important;
  .option-text {
    color: $primary-white !important;
  }
}
.formInput:focus {
  outline: none;
}

.cancel-btn-padding {
  padding: 10px 25px !important;
  border: none !important;
  border-radius: 5px !important;
}
.white-background {
  background: $primary-white !important;
}

.wizard-next-button {
  color: $primary-white !important;
  background: $primary-blue !important
}

.wizard-previous-button {
  color: $primary-blue !important;
  background: $dark-blue !important
}

@media screen and (max-width: 480px) {
  .btn-wrapper {
    flex-direction: column !important;
  }
  .btn-submit,
  .nextBtn,
  .back-btn, .wizard-next-button, .wizard-previous-button {
    width: 100% !important;
  }
  .prev-btn {
    width: 100% !important;
  }

  .btn-mobile {
    width: 100% !important;
  }

  .space-top {
    margin-top: 10px !important;
  }
  .nextBtn {
    margin: 5px 0 !important;
    width: 100% !important;
  }
  .formInput {
    width: 50% !important;
  }
  .cancel-btn-padding {
    padding: 5px !important;
    font-size: 12px !important;
  }
}
