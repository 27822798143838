.red-circle { 
 background-color: $primary-red;
}

.blue-circle {
    background-color: $blue-500;
}

.small-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    flex-shrink: 0;
}