.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  height: 5px;
  background: $black-400 ;
  background-image: linear-gradient($primary-red, $primary-red);
  background-repeat: no-repeat;
  border: none !important;
  outline: none;
}

.slider:hover {
  opacity: 1;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: $primary-red;
  cursor: pointer;
  border-radius: 50%;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: $primary-red;
  cursor: pointer;
}

.slider::-ms-track {
  width: 19px;
  height: 5px;
  background: $primary-red;
  cursor: pointer;
  border: none;
}
